import React, { useState } from "react";
import styled from "styled-components";
import { space, layout } from "styled-system";
import { useSpring, animated } from "react-spring";
import { Link } from "gatsby";
import { Flex } from "rebass/styled-components";
import Logo from "./Logo";
import { CloseButton } from "gatsby-theme-jules";
import { auto } from "eol";

const MenuItems = styled.ul`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 410px;
  margin: 0;
  ${space};
  ${layout}
`;

const MenuItem = styled.li`
  padding: 0 0.5rem;
  a {
    color: ${(props) => props.theme.colors.text};
  }
  ${layout}
`;

MenuItem.defaultProps = {
  display: ["block", "inline-block"],
};

const ToggleLink = styled.a`
  ${space};
  ${layout};
`;

const MobNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  background: #333;
  height: 100vh;
  width: 100vw;
  padding: 4rem 2rem;
`;

const MobMenu = ({ style, toggle }) => (
  <animated.div style={{ position: "relative", zIndex: 1, ...style }}>
    <MobNav>
      <CloseButton onClick={toggle} text="white">
        Close
      </CloseButton>
      <Link to="/">
        <Logo />
      </Link>
      <MenuItem>
        <Link to="/loans">Loans</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/mortgage-calculator">Mortgage Calculator</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/about/about-us">About us</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/guides">Guides</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/contact">Contact</Link>
      </MenuItem>

      {/* {config.links.map(link => (
        <MenuItem key={link.title}>
          <Link to={link.link}>{link.title}</Link>
        </MenuItem>
      ))} */}
    </MobNav>
  </animated.div>
);

/** Our main navigation component */
const Nav = ({ bg }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const mobAnimation = useSpring({});

  return (
    <>
      {toggleMenu && (
        <MobMenu style={mobAnimation} toggle={() => setToggleMenu(false)} />
      )}
      <Flex
        as="nav"
        py={3}
        alignItems="flex-end"
        justifyContent="center"
        flexWrap="Wrap"
        sx={{ borderBottom: "2px solid #EDF2F7" }}
      >
        <MenuItems
          p={1}
          display={["none", "block"]}
          style={{ textAlign: "right" }}
        >
          <MenuItem>
            <Link to="/loans">Loans</Link>
          </MenuItem>
          {/* <MenuItem>
            <Link to="/insurance">Insurance</Link>
          </MenuItem> */}
          <MenuItem>
            <Link to="/kiwisaver">KiwiSaver</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/mortgage-calculator">Calculators</Link>
          </MenuItem>
        </MenuItems>

        <MenuItems
          p={0}
          display={["block"]}
          style={{ textAlign: "center", flex: "1 1 180px" }}
        >
          <MenuItem>
            <Link to="/">
              <Logo />
            </Link>
          </MenuItem>
        </MenuItems>

        <MenuItems
          p={1}
          display={["none", "block"]}
          style={{ textAlign: "left" }}
        >
          <MenuItem>
            <Link to="/about/about-us">About us</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/guides">Guides</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/contact">Contact</Link>
          </MenuItem>
        </MenuItems>
        <ToggleLink
          onClick={() => setToggleMenu(!toggleMenu)}
          p={1}
          mr={[0, 3]}
          mt={"10px"}
          display={["block", "none"]}
          style={{ textAlign: "center", flex: "1 1 500px" }}
        >
          <Flex flexDirection="column-reverse">
            <span>Menu</span>
            {/* <svg
              height="32px"
              id="Layer_1"
              // style="enable-background:new 0 0 32 32;"
              style={{ margin: "10px auto" }}
              version="1.1"
              viewBox="0 0 32 32"
              width="32px"
            >
              <path
                style={{ fill: "#000" }}
                d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
              />
            </svg> */}
          </Flex>
        </ToggleLink>
      </Flex>
    </>
  );
};

export default Nav;
