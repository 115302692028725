import React from "react";

import { graphql, Link } from "gatsby";
import { Box, Button } from "rebass/styled-components";
import { Text, Heading } from "rebass/styled-components";
import styled from "styled-components";
import {
  Seo,
  ArticleCard,
  Testimonial,
  Container,
  Section,
  Header,
  Layout,
} from "gatsby-theme-jules";
import PartnerLogos from "gatsby-theme-jules/src/components/ui/PartnerLogos";

const ScrollContainer = styled.div.attrs((props) => ({
  maxwidth: props.maxwidth || "none",
  padding: props.padding || "0 0 1rem 0",
  colwidth: props.colwidth || "18rem",
}))`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(20, ${(props) => props.colwidth});
    grid-gap: 15px;
  }
  display: flex;
  overflow-x: auto;
  scroll-snap-coordinate: 0 0;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
  padding: ${(props) => props.padding};
  max-width: ${(props) => props.maxwidth};
  margin: 0 auto;

  > a {
    float: left;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export default ({ data, location }) => {
  const {
    title,
    subtitle,
    rateSectionHeading,
    rateSectionText,
    rate,
    headerImage,
    partnerLogos
  } = data.datoCmsHomePage;

  return (
    <Layout>
      <Seo
        title="Mortgage Broker New Zealand"
        description="Century 21 Financial Is The Gold Standard Mortgage Broker in New Zealand. Click Here To See How We Can Help"
        url={location.href}
      />
      <Header
        bg="none"
        title={title}
        subTitle={subtitle}
        includeCTA
        image={headerImage}
      />
      <Section bg="primary" py="5">
        <Container p={3} initiallyVisible="true">
          <Box width={[1, 2 / 3]}>
            <Heading
              as="h2"
              fontSize={[6, 7]}
              fontFamily="subheading"
              fontWeight="500"
              sx={{ textTransform: "uppercase" }}
              mb={3}
              justifyContent="space-between"
            >
              {rateSectionHeading}
            </Heading>
            <Text fontSize={[4, 5]}>{rateSectionText}</Text>
          </Box>

          <Box width={[1, 1 / 3]}>
            <Text
              fontSize={[6, 5]}
              textAlign={["center", "center"]}
              fontFamily="heading"
              mb={4}
              ml={[0, 3, 5]}
              mt={[4, 0]}
            >
              Interest rate from
            </Text>
            <Text
              ml={[0, 3, 5]}
              fontFamily="subheading"
              fontSize={["8.5rem", "4.5rem", 9]}
              textAlign={["center", "center"]}
              lineHeight={["4rem", "2rem"]}
            >
              {rate}%
            </Text>
            <Text
              fontFamily="subheading"
              fontSize={[5, 5, 4]}
              mt={["2rem", "1.5rem"]}
              textAlign={["right"]}
              mr={["3rem", "1rem"]}
            >
              per annum
            </Text>
          </Box>
        </Container>
      </Section>
      <Section py={4}>
        <Container py="4">
          <Heading
            as="h2"
            width={1}
            fontFamily="heading"
            fontWeight="500"
            sx={{ textTransform: "uppercase" }}
            fontSize={[5, 5]}
            mb={4}
          >
            What our clients say
          </Heading>

          <ScrollContainer>
            {data.allDatoCmsTestimonial.edges.map(({ node }) => (
              <Testimonial name={node.name} text={node.testimonialText} />
            ))}
          </ScrollContainer>
        </Container>
      </Section>
      <Section bg="lightGrey" py={5}>
        <Container p={3}>
          <Box width={1}>
            <Heading
              as="h2"
              width={1}
              fontFamily="heading"
              fontWeight="500"
              sx={{ textTransform: "uppercase" }}
              fontSize={[5, 5]}
              mb={4}
            >
              Latest Articles
            </Heading>
          </Box>
          {data.allDatoCmsBlog.edges.map(({ node }) => {
            const image = node.headerImage || {};

            return (
              <ArticleCard
                width={300}
                slug={`/guide/${node.slug}`}
                title={node.title}
                subTitle={node.subtitle}
                category={node.category.title}
                image={image}
                m="0"
                mt={3}
                mr={[0, 3]}
              />
            );
          })}
        </Container>
        <Box margin="0 auto" textAlign="center">
          <Link to="/guides">
            <Button>View more articles</Button>
          </Link>
        </Box>
      </Section>
      <Section py={5}>
        <Container py="4">
        <Heading
              as="h2"
              width={1}
              fontFamily="heading"
              fontWeight="500"
              sx={{ textTransform: "uppercase" }}
              fontSize={[5, 5]}
              mb={4}
            >
              Our Partners
            </Heading>
          <PartnerLogos logos={partnerLogos}/>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query FlamingoIndexQuery {
    datoCmsHomePage {
      title
      subtitle
      rateSectionHeading
      rateSectionText
      rate
      headerImage {
        fluid(
          maxWidth: 600
          maxHeight: 400
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      partnerLogos {

      alt
      fluid(
          maxWidth: 200
          maxHeight: 70
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
    }
    }
    allDatoCmsTestimonial(limit: 10) {
      edges {
        node {
          testimonialText
          name
        }
      }
    }
    allDatoCmsBlog(limit: 3) {
      edges {
        node {
          title
          subtitle
          category {
            title
          }
          slug
          headerImage {
            fluid(
              maxWidth: 600
              maxHeight: 400
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      
    }
  }
`;
