import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { Container } from "gatsby-theme-jules";
import Nav from "./Nav";
const Header = ({
  title,
  subTitle,

  image,
  category,
  includeCTA
}) => {
  const data = useStaticQuery(graphql`
    query flamingoImgQuery {
      file(relativePath: { eq: "ImageDefault.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box as="header" bg="lightGrey" sx={{ position: "relative" }}>
      <Box px={"7rem"}>
        <Nav />
      </Box>
      <Container alignItems="center" mx={-4}>
        <Box
          sx={{ position: "relative" }}
          py={[3, 4]}
          px={4}
          width={image.fluid ? [1, 1 / 2] : [1]}
          textAlign={!image.fluid && "center"}
        >
          <Text color="primary" fontFamily="subheading" mb="2" fontSize={[4]}>
            {category}
          </Text>
          <Heading as="h1" fontSize={[5, 5, 7]} lineHeight={"3rem"} mb="1.4rem">
            {title}
          </Heading>
          <Text as="p" fontSize={[3, 3, 4]} color="text" mb=".5rem">
            {subTitle}
          </Text>
          {includeCTA && (
            <>
              <Link to="/appointment">
                <Button mt={3} variant="primary" minWidth={["100%", "100%", 0]}>
                  Book a free appointment
                </Button>
              </Link>
              <Link to="/about/about-us">
                <Button
                  ml={[0, 0, 2]}
                  mt={3}
                  variant="ghost"
                  minWidth={["100%", "100%", 0]}
                >
                  Why Flamingo
                </Button>
              </Link>
            </>
          )}
        </Box>
        {image.fluid && (
          <Box width={[1, 1 / 2]} py={[3, 5]} px={4}>
            <Img
              fluid={image.fluid || data.file.childImageSharp.fluid}
              alt={image.alt || title}
              imgStyle={{ borderRadius: "10px 0 10px" }}
              loading="eager"
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  backgroundImage: PropTypes.string
};

Header.defaultProps = {
  image: {}
};
export default Header;
